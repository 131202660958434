import axios from '../instance'
import { IGetOrderPront, IParamCreateReceiptLog, IRunningNumberForDealer, IRunningNumberOrderRecript, IlistOrderRecript, ResponseGetReceipt, ResponseRunningNumberForDealer, ResponseListOrder } from './type'


export const getOrderReceiptById = async (params: IGetOrderPront) => {
	try {
		const { data } = await axios.post('/receipt', params)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const listOrderReceipt = async (bodyData: IlistOrderRecript): Promise<ResponseListOrder[]> => {
	try {
		const { data } = await axios.post('/receipt/list-order-recript',bodyData )
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const createReceiptLog = async (params: IParamCreateReceiptLog): Promise<ResponseGetReceipt> => {
	try {
		const { data } = await axios.post('/receipt/add-log', params)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const RunningNumberOrderReceipt = async (
	bodyData: IRunningNumberOrderRecript
) => {
	try {
		const { data } = await axios.post(
			'/receipt/running-number-receipt',
			bodyData
		)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const RunningNumberForDealerReceipt = async (
	bodyData: IRunningNumberForDealer
):Promise<ResponseRunningNumberForDealer> => {
	try {
		const { data } =  await axios.post(
			'/receipt/running-number-for-dealer',
			bodyData
		)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}